import { useEffect, useRef, useState } from 'react'
import LazyLoad from 'react-lazyload'

import Ad from '@/components/Ad/Ad'
import useDirectories from '@/utils/useDirectories'
import usePatreonTier from '@/utils/usePatreonTier'

import styles from './Leaderboard.module.scss'

const cpmStarPercent = 0.5

const Leaderboard = () => {
  const patreonTier = usePatreonTier()
  const { query } = useDirectories()
  const cpmstarRef = useRef<HTMLDivElement>(null)
  const [cpmStarRefIsRendered, setCpmStarRefIsRendered] = useState<boolean>(false)
  const [randomRoll, setRandomRoll] = useState<number>(0)

  useEffect(() => {
    setRandomRoll(Math.random())
    const interval = setInterval(() => {
      setRandomRoll(Math.random())
    }, 30000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (randomRoll > 0) {
      setCpmStarRefIsRendered(randomRoll < cpmStarPercent)
    }
  }, [randomRoll])

  useEffect(() => {
    if (cpmStarRefIsRendered) {
      try {
        // @ts-ignore
        window.cpmstarx = window.cpmstarx || {}
        // @ts-ignore
        window.cpmstarx.libcmd = window.cpmstarx.libcmd || []
        // @ts-ignore
        window.cpmstarx.libcmd.push({
          el: cpmstarRef.current,
          kind: 'asynctagfetch',
          pid: 89444,
          pindex: 0,
        })
      } catch (e) {
        console.warn(e)
      }
    }
  }, [cpmStarRefIsRendered])

  if (patreonTier && !query?.ads) return null

  /* Ad Setup:
     - 50.0% CPMStar
     - 10.0% SCG
     - 20.0% Cardzilla

     - Half of remainder split between SCM
     - Other half of remainder goes to EDHREC Patreon

     - Note that the CPMStar container div must show on initial render (randomRoll=0)
  */

  const ad =
    randomRoll === 0 ? null : randomRoll < cpmStarPercent ? null : randomRoll < 0.55 ? (
      <Ad id='scg1' />
    ) : randomRoll < 0.6 ? (
      <Ad id='scg2' />
    ) : randomRoll < 0.8 ? (
      <Ad id='cardzilla' />
    ) : randomRoll < 0.8 + 0.2 * (1 / 14) ? (
      <Ad id='archidekt' />
    ) : randomRoll < 0.8 + 0.2 * (2 / 14) ? (
      <Ad id='ch' />
    ) : randomRoll < 0.8 + 0.2 * (3 / 14) ? (
      <Ad id='csb' />
    ) : randomRoll < 0.8 + 0.2 * (4 / 14) ? (
      <Ad id='edhrecast' />
    ) : randomRoll < 0.8 + 0.2 * (5 / 14) ? (
      <Ad id='edhrecap' />
    ) : randomRoll < 0.8 + 0.2 * (5.25 / 14) ? (
      <Ad id='spellifyDark1' />
    ) : randomRoll < 0.8 + 0.2 * (5.5 / 14) ? (
      <Ad id='spellifyDark2' />
    ) : randomRoll < 0.8 + 0.2 * (5.75 / 14) ? (
      <Ad id='spellifyLight1' />
    ) : randomRoll < 0.8 + 0.2 * (6 / 14) ? (
      <Ad id='spellifyLight2' />
    ) : randomRoll < 0.8 + 0.2 * (7 / 14) ? (
      <Ad id='fabrec' />
    ) : (
      <Ad id='edhrecPatreon' />
    )

  return (
    <div className={styles.container}>
      <div className={styles.leaderboard}>
        <LazyLoad className='h-100'>
          {randomRoll < cpmStarPercent && (
            <div className={styles.cpmstarWrapper}>
              <div className={styles.cpmstar} ref={cpmstarRef} />
            </div>
          )}
          {ad}
        </LazyLoad>
      </div>
      <div className='mvLeaderboard' />
    </div>
  )
}

export default Leaderboard
